import React, { ReactElement, ReactNode, useState } from 'react';
import AddNodeButton from '../AddNodeButton';
import {
  Handle,
  NodeProps,
  Position,
  useReactFlow,
  Node,
  NodeResizer,
  NodeToolbar,
} from '@xyflow/react';
import { cx } from '../../../helpers/utils';
import { NodeData, WorkflowStatus } from '../../../graphql/operations';
import { WorkflowStatusIcon } from '../WorkflowStatusIcon';
import { workflowDuration } from '../../../services/Workflow';
import { Button } from '../../../components/buttons';
import { Check, GripVertical, Info, Trash, X } from 'lucide-react';
import { TextInput } from '../../../components/TextInput';
import { nodeTypes } from '.';
import { Popover } from '../../../components/Popover';
import JsonView from 'react-json-view';
import { Menu } from '../../../components/Menu';
import { formatDistance } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { trackRenameWorkflowNode } from '../../../helpers/analytics';
import { useWorkflowId } from '../WorkflowIdContext';

export type WorkflowNode<T = object> = Node<
  {
    displayName: string;
    execution?: {
      status: WorkflowStatus;
      startedAt?: number;
      completedAt?: number;
      output?: unknown;
      error?: { message: string };
    };
    recentExecutionData?: NodeData;
    isValid?: boolean;
  } & T,
  keyof typeof nodeTypes
>;
export type WorkflowNodeProps<T = object> = NodeProps<WorkflowNode<T>>;

type BaseNodeProps = {
  icon?: ReactNode;
  handles?: ReactNode | undefined | null;
  children?: any;
  workflowNode: WorkflowNodeProps;
  hideId?: boolean;
  noResize?: boolean;
  minHeight?: number;
};

export function BaseNode({
  icon,
  children,
  handles,
  workflowNode,
  hideId,
  noResize,
  minHeight,
}: BaseNodeProps): ReactElement {
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const state = useReactFlow();
  const execution = workflowNode.data.execution;
  const { workflowId } = useWorkflowId();
  const title = workflowNode.data.displayName ?? workflowNode.type;
  const isValid = workflowNode.data.isValid;
  const recentExecutionOutput =
    workflowNode.data.recentExecutionData?.output ?? '';

  if (execution) {
    const duration = workflowDuration(
      execution.startedAt,
      execution.completedAt
    );
    return (
      <div
        className={cx(
          `relative min-w-96 cursor-default rounded-lg border bg-white motion-safe:transition-shadow`,
          workflowNode.data.execution?.status === 'COMPLETE'
            ? 'border-green-500'
            : 'border-slate-200'
        )}
      >
        <div className="flex justify-between gap-2">
          <div className="flex w-full items-center justify-between gap-2 border-b border-slate-200 px-5 py-3">
            {icon}
            <div className="text-base font-bold leading-none">{title}</div>
            {duration && (
              <div className="ml-auto pr-1 text-sm font-normal">{duration}</div>
            )}
            {execution.status === 'COMPLETE' ||
            execution.status === 'FAILED' ? (
              <Menu>
                <Menu.Trigger>
                  <Button
                    size="small"
                    variant="naked"
                    startIcon={<WorkflowStatusIcon status={execution.status} />}
                  />
                </Menu.Trigger>
                <div className="flex flex-col items-start pb-2">
                  <div className="flex h-[24px] items-center rounded-full border border-slate-200 px-3 font-mono text-xs font-medium text-slate-600">
                    {workflowNode.id}
                  </div>
                </div>
                <div className="flex flex-col gap-2 p-2">
                  {execution.startedAt ? (
                    <div className="text-xs text-slate-500">
                      <FormattedMessage
                        defaultMessage="Started: {date}"
                        id="nOZpKS"
                        values={{
                          date: formatDistance(
                            new Date(execution.startedAt),
                            Date.now(),
                            {
                              addSuffix: true,
                            }
                          ),
                        }}
                      />
                    </div>
                  ) : null}
                  {execution.startedAt && execution.completedAt ? (
                    <div className="text-xs text-slate-500">
                      <FormattedMessage
                        defaultMessage="Duration: {duration}"
                        id="Sczo8n"
                        values={{
                          duration,
                        }}
                      />
                    </div>
                  ) : null}
                  {execution.error ? (
                    <>
                      <div className="text-xs text-red-500">
                        <FormattedMessage
                          defaultMessage="Error message:"
                          id="zbpOKH"
                        />
                      </div>
                      <div className="text-xs text-red-500">
                        {execution.error.message}
                      </div>
                    </>
                  ) : null}
                </div>
                {execution.output ? (
                  <JsonView
                    collapseStringsAfterLength={10}
                    name={workflowNode.id}
                    src={
                      typeof execution?.output === 'string'
                        ? {
                            output: execution?.output,
                          }
                        : execution?.output
                    }
                  />
                ) : null}
              </Menu>
            ) : (
              <WorkflowStatusIcon status={execution.status} />
            )}
          </div>
        </div>
        <Handle className="opacity-0" type="target" position={Position.Top} />
        {children && <div className="relative p-5">{children}</div>}
        {handles}
      </div>
    );
  }
  return (
    <div
      className={cx(
        `b-slate-200 group flex h-full w-full min-w-96 flex-grow cursor-default flex-col overflow-visible rounded-2xl border bg-white shadow-sm transition-all hover:border-brand motion-safe:transition-shadow`,
        workflowNode.dragging ? 'shadow-xl ring-opacity-0' : '',
        workflowNode.selected
          ? 'border-brand ring-2 ring-brand-100'
          : 'border-slate-200',
        isValid === false
          ? 'border-2 border-dashed !border-slate-300 ring-transparent hover:!border-brand'
          : ''
      )}
    >
      <div className="flex w-full items-center justify-between gap-2 px-5 py-3">
        {icon}
        <TextInput
          type="text"
          variant="naked"
          inputProps={{
            className:
              'nodrag grow p-1 text-base text-slate-800 hover:bg-slate-100 rounded font-semibold leading-none focus:border-transparent focus:bg-slate-100',
          }}
          value={title}
          onBlur={() => {
            trackRenameWorkflowNode({ workflowId, type: workflowNode.type });
          }}
          onChange={(displayName) => {
            state.updateNodeData(workflowNode.id, {
              displayName,
            });
          }}
        />
        {!hideId && (
          <div className="ml-3 flex h-[24px] items-center rounded-full border border-slate-200 bg-slate-50 px-3 font-mono text-xs font-medium text-slate-600">
            {workflowNode.id}
          </div>
        )}
      </div>
      <Handle className="opacity-0" type="target" position={Position.Top} />
      {children && (
        <div className="nodrag nopan nowheel flex flex-grow flex-col border-t p-5">
          {children}
        </div>
      )}
      <NodeResizer
        minWidth={384}
        minHeight={minHeight ?? 256}
        maxWidth={800}
        lineClassName="!border-4 !opacity-0"
        handleClassName="!size-8 !opacity-0"
        isVisible={!noResize}
      />
      {handles}
      <button className="absolute -left-6 top-0 h-full cursor-grab p-2 opacity-0 transition-all duration-100 hover:-left-10 hover:opacity-100 group-hover:-left-10 group-hover:opacity-100">
        <GripVertical className="fill-slate-400 stroke-slate-400" />
      </button>
      {workflowNode.type !== 'StartNode' && (
        <NodeToolbar
          isVisible={workflowNode.selected}
          align="end"
          position={Position.Top}
        >
          <div className="flex items-center gap-2">
            {removeConfirm ? (
              <>
                <Button
                  variant="secondaryOutline"
                  startIcon={<X size="1rem" />}
                  onClick={() => setRemoveConfirm(false)}
                />
                <Button
                  color="error"
                  startIcon={<Check size="1rem" />}
                  onClick={async () => {
                    await state.deleteElements({ nodes: [workflowNode] });
                    setRemoveConfirm(false);
                  }}
                />
              </>
            ) : (
              <>
                {recentExecutionOutput && (
                  <Popover
                    placement="right-start"
                    content={
                      <div className="max-h-80 overflow-y-auto">
                        <p className="mb-2 font-bold">
                          <FormattedMessage
                            defaultMessage="The most recent execution of this step resulted in the
                          following output:"
                            id="fhcGDr"
                          />
                        </p>
                        <p>{JSON.stringify(recentExecutionOutput)}</p>
                      </div>
                    }
                  >
                    <Button
                      variant="secondaryOutline"
                      startIcon={<Info size="1rem" />}
                    />
                  </Popover>
                )}
                <Button
                  variant="secondaryOutline"
                  startIcon={<Trash size="1rem" />}
                  onClick={() => setRemoveConfirm(true)}
                />
              </>
            )}
          </div>
        </NodeToolbar>
      )}
    </div>
  );
}

export function SingleSourceNode({
  icon,
  children,
  workflowNode,
  hideId,
  noResize,
  minHeight,
}: BaseNodeProps): ReactElement {
  const reactFlow = useReactFlow();
  const edges = reactFlow.getEdges();
  const hasTarget = edges.some((e) => e.source === workflowNode.id);
  const execution = workflowNode.data.execution;

  return (
    <BaseNode
      workflowNode={workflowNode}
      hideId={hideId}
      noResize={noResize}
      icon={icon}
      minHeight={minHeight}
      handles={
        <div>
          {workflowNode.type !== 'StartNode' && (
            <Handle
              type="target"
              className={cx('!size-2 opacity-0', execution ? 'invisible' : '')}
              position={Position.Top}
            />
          )}
          <Handle
            type="source"
            className={cx(
              '!size-3 !border !border-brand !bg-white !ring-2 !ring-white',
              execution ? 'invisible' : ''
            )}
            position={Position.Bottom}
          />
          <NodeToolbar
            className="nodrag top-3"
            isVisible={!hasTarget && !execution}
            position={Position.Bottom}
          >
            <AddNodeButton source={workflowNode.id} />
          </NodeToolbar>
        </div>
      }
    >
      {children}
    </BaseNode>
  );
}
