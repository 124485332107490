import React, { ReactElement } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { useAddableSpaces } from '../../../services/Space';
import { Select } from '../../../components/Select';
import { SpaceIcon } from '../../Common/icons';

export function SaveToSpace(
  props: WorkflowNodeProps<{ space: string }>
): ReactElement {
  const addableSpaces = useAddableSpaces();
  const reactFlow = useReactFlow();

  return (
    <SingleSourceNode
      icon={
        <SpaceIcon className="size-8 rounded-lg bg-brand-50 p-2 text-brand" />
      }
      workflowNode={props}
      noResize
    >
      <div className="nodrag">
        <Select
          full
          value={props.data.space}
          onChange={(space) => reactFlow.updateNodeData(props.id, { space })}
          options={addableSpaces.map((ii) => ({
            value: ii.id,
            icon: ii.icon,
            label: ii.name,
          }))}
        />
      </div>
    </SingleSourceNode>
  );
}
